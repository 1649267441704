import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Master Plumber in Harford County, MD"
        keywords={[`residential plumber`, `master plumber`, `plumber in maryland`]}
      />
    </Layout>
  );
}

export default IndexPage;
